import React from 'react';
import styles from './Home.module.scss';
import Hero from '../../components/Hero/Hero';
import Hero1 from "../../assets/images/HeroHome.jpg";
import ThumbLayout from '../../components/ThumbLayout/ThumbLayout'; 
import ThumbnailsData from '../../constants/ThumbnailsData';

const Home = (props) =>{
  console.log(props);
  
  return(
    <div>
			{/* { Hero1 &&
				<div className={styles.heroHome}>
					<div className={styles.mainTitle}>
						<h1><span className={styles.bold}>K</span>azue <span className={styles.bold}>O</span>tsuki</h1>
						<h5>Design practical UI with technical acumen</h5>
					</div>
					<div>
						<img className={styles.heroImg} src={Hero1} alt="Kazue Otsuki: Design practical UI with technical acumen" />
					</div>
				</div>
			} */}
      <Hero 
        imgPath={Hero1} 
        imgAlt="Kazue Otsuki"
      />
      <ThumbLayout thumbnails={ThumbnailsData} />
    </div>
  )
}

export default Home;
