import React from 'react';
import './Hero.scss';

const Hero = (props) => {
  const {
		MainTitle,
    imgPath,
    imgAlt
  } = props;

    return(
			<div className="hero">
				<div className="MainTitle">
					<h2>{MainTitle}</h2>
				</div>
				<div className="imageArea">
					<img className="heroImg" src={imgPath} alt={imgAlt} />
				</div>
			</div>
    )
}

export default Hero;

